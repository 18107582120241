import { Box } from "@mui/material";
import { useEffect } from "react";
import { License, PriceCheck } from "./declarations/license";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";


const paypalOptions = {
    "client-id": `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
    components: "buttons",
    currency: "USD",
  };

var finalPrice: number;

export interface PayPalPaymentProps {
  licenses: License[];
  priceCheck: PriceCheck;
  onChange?: (orderValue: PriceCheck) => void;
  onClick: () => void;
  onApprove: () => void;
  onError: () => void;
  onCancel: () => void;
}

export function PayPalPayment({
  licenses,
  priceCheck,
  onChange: handleParent,
  onClick,
  onApprove,
  onError,
  onCancel,
}: PayPalPaymentProps) {

  useEffect(() => {
    handleParent && handleParent(priceCheck);
    console.log("PayPal handleParent(): priceCheck = " + priceCheck.price);
    finalPrice = priceCheck.price;
  }, [priceCheck, handleParent]);

  const handleClick = async () => {
    console.log("onClick");
    onClick();
  };

  const handleApprove = async () => {
    console.log("onApprove");
    onApprove();
  };

  const handleError = async () => {
    console.log("onError");
    onError();
  };

  const handleCancel = async () => {
    console.log("onCancel");
    onCancel();
  };

  return (
    <Box>
      <PayPalScriptProvider options={paypalOptions}>
        <PayPalButtons
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  currency_code: "USD",
                  // value: priceCheck.price.toString(),
                  value: finalPrice.toString(),
                },},],
              }).then((orderId) => {
              // Your code here after create the order
              return orderId;
              });
            }
          }
          onClick={handleClick}
          onApprove = { async (data, action) => {
              const order = await action.order;
              if (order)
                await order.capture();
              console.log("order", order);
              handleApprove();
            }
          }
          onError={handleError}
          onCancel={handleCancel}
          disabled={false}
          fundingSource={"paypal"}
          // env={env}
          // client={client}
          // style={style}
        />
      </PayPalScriptProvider>
    </Box>
  );
}
