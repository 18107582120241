import React from "react";
import { Box } from "@mui/material";
import FormHeader from "./FormHeader";
import FormBody from "./FormBody";

interface FormBodyProps {
  formType: string;
}

class Form extends React.Component<FormBodyProps> {

  render() {
    return (
      <Box sx={{ py: 2 }}>
        <FormHeader formType={this.props.formType}/>
        <FormBody formType={this.props.formType}/>
      </Box>
    );
  }
}

export default Form;
