import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";

export interface CustomDialogProps {
  title?: string;
  body?: string;
  opened?: boolean;
}

export function CustomDialog({
  title,
  body,
  opened = false,
}: CustomDialogProps) {
  const [open, setOpen] = useState(opened);
  const handleClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
