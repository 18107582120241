import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js';
import { BrowserRouter } from 'react-router-dom';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

(async () => {

  //Stripe
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const {clientSecret} = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe_secret`,
                                     {method: 'post', 
                                     credentials: 'include', 
                                     headers: {
                                        'Content-Type': 'application/json',
                                        'SameSite': 'None',
                                        'Secure': 'true'
                                     },
                                 })
    .then(r => r.json())
    .catch((err) => {
      console.log('Error: ', err);
    });
  const stripeOptions = {clientSecret: clientSecret};

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <Elements stripe={stripePromise} options={stripeOptions}>
          <App />
        </Elements>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
