import * as yup from "yup";

yup.setLocale({
  string: {
    email: "Please enter a valid email address.",
  },
  mixed: {
    required: "This field is required.",
  },
  number: {
    integer: "Please enter a number greater than 0.",
  }
});

export const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  numLicenses: yup.number().required().positive().integer(),
  license: yup.string(),
  whereHeard: yup.string(),
  addressForInvoice: yup.string(),
  vatNumberForInvoice: yup.string(),
});
