import { Box } from "@mui/material";
import { useEffect } from "react";
import { License, PriceCheck } from "./declarations/license";
import { PaymentElement } from "@stripe/react-stripe-js";


export interface StripePaymentProps {
  // sx?: Record<string, object | string | number>;
  licenses: License[];
  priceCheck: PriceCheck;
  onChange?: (orderValue: PriceCheck) => void;
}

export function StripePayment({
  // sx,
  licenses,
  priceCheck,
  onChange: handleParent,
}: StripePaymentProps) {

  // Call stripe.confirmCardPayment() with the client secret.
  // const PaymentElement = elements.getElement('payment');

  useEffect(() => {
    handleParent && handleParent(priceCheck);
    console.log("Stripe handleParent(): priceCheck = " + priceCheck.price);
  }, [priceCheck, handleParent]);

  return (
    <Box>
      <PaymentElement />
    </Box>
  );
}
