import { Control, FieldError } from "react-hook-form";

export interface FormValues {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  numLicenses: number;
  license: string;
  whereHeard: string;
  addressForInvoice: string;
  vatNumberForInvoice: string;
}

export type FormFields =
  | "firstName"
  | "lastName"
  | "company"
  | "email"
  | "numLicenses"
  | "license"
  | "whereHeard"
  | "addressForInvoice"
  | "vatNumberForInvoice";

export interface ControlledComponentProps {
  name: FormFields;
  label: string;
  control: Control<FormValues>;
  sx?: Record<string, object | string | number>;
  error?: FieldError;
  errorState?: boolean;
  required?: boolean;
};

export const defaultValues: FormValues = {
  firstName: "",
  lastName: "",
  company: "",
  email: "",
  numLicenses: 1,
  license: "",
  whereHeard: "",
  addressForInvoice: "",
  vatNumberForInvoice: "",
};