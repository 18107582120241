import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { ControlledComponentProps } from "./declarations/form";

export interface ControlledInputProps extends ControlledComponentProps {
  fullWidth?: boolean;
  multiline?: boolean;
  value?: (string | number);
  rows?: number;
  type?: string;
  inputProps?: object;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export function ControlledInput({
  name,
  type,
  control,
  label,
  error,
  sx,
  rows,
  fullWidth,
  multiline,
  required,
  errorState,
  inputProps,
  value: valueFromParent,
  onChange: handleParent,
}: ControlledInputProps): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <TextField
            onChange={(e) => {
              onChange(e);
              handleParent && handleParent(e);
            }}
            type={type}
            value={valueFromParent ?? value}
            label={`${label} ${required ? "*" : ""}`}
            sx={sx}
            rows={rows}
            multiline={multiline}
            fullWidth={fullWidth}
            error={errorState || !!error}
            helperText={error?.message}
            inputProps={inputProps}
          />
        );
      }}
    />
  );
}
