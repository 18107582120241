export interface ErrorDialog {
  title: string;
  message: string;
}

export type RequestErrorMessage = "Network Error";
export const requestErrors: Record<RequestErrorMessage, ErrorDialog> = {
  "Network Error": {
    title: "Network Error",
    message:
      "Unable to connect our servers. Please try again. If the problem persists, please contact us.",
  },
};

export type PaymentErrorMessage = "Payment Error";
export const paymentErrors: Record<PaymentErrorMessage, ErrorDialog> = {
  "Payment Error": {
    title: "Payment Error",
    message:
      "There has been an error with your payment. Please try again. If the problem persists, please contact us.",
  },
};
