import { AlertColor } from "@mui/material";

export type LicenseStatus = "personal1_old" | "personal1_new" | "personal1_used" |
    "business1_old" | "business1_new" | "business1_used" |
    "personal2" | "business2" | "invalid" | "empty" | "no_hashes";

export type AllLicenseStatus = "license_field_empty" | "no_hashes" | "hash_invalid" |
    "all_hashes_invalid" | "discounted_upgrade_eligible" | "free_upgrade_eligible" | "same_license_type" |
    "already_redeemed" | "business1_to_personal2";

export interface License {
  hash: string;
  status: LicenseStatus;
}

export interface LicenseAlert {
  severity: AlertColor;
  message: string;
}

export interface PriceCheck {
  price: number;
  fullPrice: number;
}

export function computeAllLicenseStatus(formType: string, licenses: License[]) {
  /*
  Returns AllLicenseStatus.

  For personal licenses, it's trying to find one valid license to use for a discount.
  If it finds one, it returns it. If not, it returns the status of the ones it does find.
  */

  let personal1_old: boolean = false;
  let personal1_new: boolean = false;
  let personal1_used: boolean = false;
  // let personal1: boolean = false;
  let personal2: boolean = false;

  let business1_old: boolean = false;
  let business1_new: boolean = false;
  let business1_used: boolean = false;
  let business1: boolean = false;
  let business2: boolean = false;

  //Figure out what's in it.
  let i: number;
  for (i = 0; i < licenses.length; i++)
  {
    const license = licenses[i];

    if (license.status === "no_hashes")
      return "no_hashes";
    else if (license.status === "empty")
      return "license_field_empty";

    //Personal 1 License
    else if (license.status === "personal1_old")
      personal1_old = true;
    else if (license.status === "personal1_new")
      personal1_new = true;
    else if (license.status === "personal1_used")
      personal1_used = true;

    //Business 1 License
    else if (license.status === "business1_old")
      business1_old = true;
    else if (license.status === "business1_new")
      business1_new = true;
    else if (license.status === "business1_used")
      business1_used = true;

    //Version 2 License
    else if (license.status === "personal2")
      personal2 = true;
    else if (license.status === "business2")
      business2 = true;
  }

  // if (personal1_old || personal1_new || personal1_used)
  //   personal1 = true;

  if (business1_old || business1_new || business1_used)
    business1 = true;

  //Upgrade to personal (order matters here).
  if (formType.includes("personal"))
  {
    if (personal1_old)
      return "discounted_upgrade_eligible";
    else if (personal1_new)
      return"free_upgrade_eligible";
    else if (personal1_used)
      return "already_redeemed";

    else if (business1)
      return "business1_to_personal2";
    else if (personal2 || business2)
      return "same_license_type";
  }

  //Business upgrade
  else if (formType.includes("business"))
  {
    if (personal1_old || personal1_new)
      return "discounted_upgrade_eligible";
    else if (business1_old || personal2)
      return "discounted_upgrade_eligible";
    else if (business1_new)
      return "free_upgrade_eligible";

    else if (personal1_used || business1_used)
      return "already_redeemed";
    else if (business2)
      return "same_license_type";
  }

  //Invalid license. Base on number of licenses.
  if (licenses.length === 1)
    return "hash_invalid";
  else if (licenses.length === 0)
    return "license_field_empty";
  return "all_hashes_invalid";
}




export const alerts: Record<AllLicenseStatus, LicenseAlert> = {
  license_field_empty: {
    severity: "success",
    message: "empty"
  },

  no_hashes: {
    severity: "error",
    message: "Must include a 32-character hash string (i.e. random numbers and letters A-F).",
  },

  hash_invalid: {
    severity: "error",
    message: "License key is invalid.",
  },

  all_hashes_invalid: {
    severity: "error",
    message: "License keys are invalid.",
  },

  business1_to_personal2: {
    severity: "error",
    message: "This is a business license. You can only upgrade to a version 2 business license.",
  },

  same_license_type: {
    severity: "error",
    message: "This is a version 2 license. You've already upgraded."
  },

  already_redeemed: {
    severity: "error",
    message: "This license key has already been redeemed for a discount."
  },

  discounted_upgrade_eligible: {
    severity: "success",
    message: "You are eligible for a discounted upgrade.",
  },

  free_upgrade_eligible: {
    severity: "success",
    message: "You are eligible for a free upgrade.",
  },

};
