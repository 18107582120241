import { Axios } from "axios";
import { Box, Button, Snackbar, Stack, Typography } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { CustomDialog } from "./CustomDialog";
import { LicenseStringApiData } from "./declarations/apiRequests";
import { ErrorDialog, RequestErrorMessage, requestErrors } from "./declarations/errors";
import "./SuccessPage.css";

export interface SuccessPageProps {
  sx?: Record<string, object | string | number>;
}

export function SuccessPage({
  sx,
}: SuccessPageProps) {
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>();
  const [licenseString, setLicenseString] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const innerBoxStyle = useMemo(() => {
    return {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    };
  }, []);
  const axios: Axios = require("axios");

  // const gridContainer = useMemo(() => {
  //   return {
  //     display: "grid",
  //     gridTemplateColumns: "repeat(1, 1fr)",
  //     gap: 20,
  //   };
  // }, []);

  //Get the license key from the server.
  const getLicenseKey = async () => {
    let response;
    try {
      response = await axios.post("/get_license_keys", {});
    } catch (e: any) {
      setErrorDialog(requestErrors[e.message as RequestErrorMessage]);
      return;
    }

    const data: LicenseStringApiData = response?.data;
    let license_string: string = data.licenseString;

    setLicenseString(license_string);
  }

  useEffect(() => {
    getLicenseKey();
  }, );

  const handleClipboardButtonClick = () => {
    setOpenSnackbar(true);
    navigator.clipboard.writeText(licenseString);
  };

  const multiLicense = () => {
    const count = (licenseString.match(/BEGIN LICENSE/g) || []).length;
    return (count > 1);
  }

  const logo = require('../img/logo.png');

  return(
    <Stack spacing={2} sx={{ py: 2 }}>
      <Box display="flex" justifyContent="center">
        <img alt='Modern CSV Logo' src={String(logo)} />
      </Box>
      <CustomDialog
        title={errorDialog?.title}
        body={errorDialog?.message}
        opened={!!errorDialog}
      />

      {/*{multiLicense() &&
      <p>
        Thank you for purchasing! Your license keys are below.
      </p>}

      {!multiLicense() &&
      <Typography variant="body1">
        Thank you for purchasing! Your license key is below.
      </Typography>
      }*/}
      <Typography variant="body1">
        {"Thank you for purchasing! Your license key" + (multiLicense() ? "s are " : " is ") + "below."}
      </Typography>

      <Box sx={{ p: 2, backgroundColor: '#f4f4f4', borderRadius: '12px' }}>
        <div className="display-linebreak">
          {licenseString}
        </div>
      </Box>

      <Box sx={innerBoxStyle}>
        <Button variant="contained" size="medium" onClick={handleClipboardButtonClick}>
          Copy to Clipboard
        </Button>
      </Box>

      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={2000}
        message={"License key" + (multiLicense() ? "s" : "") + " copied to clipboard."}
      />

      {!multiLicense() &&
      <p>
        To install the license, open the program and click the&nbsp;
        <strong>Enter License</strong> button or go to&nbsp;
        <strong>Help</strong> -{'>'} <strong>Open License Manager</strong>.&nbsp;
        Paste the license key in the <strong>License Manager</strong> window.
      </p>
      }

      {multiLicense() &&
      <Typography variant="body1">
        Each block of text is a single key.&nbsp;
        You may distribute them to your employees as you see fit.&nbsp;
        Send your employees one license key each and in Modern CSV, have them go to&nbsp;
        <strong>Help</strong> -{'>'} <strong>Open License Manager</strong>&nbsp;
        and paste the license key in there.
      </Typography>
      }

      <Typography variant="body1">
      You will receive an email with this same information.
      </Typography>

      <Typography variant="body1">
      If you need any help, please contact us <a href="https://www.moderncsv.com/contact-us">here</a>.
      </Typography>
    </Stack>
  );
}
