import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { License, PriceCheck } from "./declarations/license";

export interface OrderTotalProps {
  licenses: License[];
  priceCheck: PriceCheck;
  onChange?: (orderValue: PriceCheck) => void;
  formType: string;
  sx?: Record<string, object | string | number>;
}

export function OrderTotal({
  licenses,
  priceCheck,
  sx,
  onChange: handleParent,
  formType,
}: OrderTotalProps) {

  // const [priceCheck, setPriceData] = useState<PriceCheck>({
  //   price: 0,
  //   fullPrice: 0
  // });

  useEffect(() => {
    handleParent && handleParent(priceCheck);
    console.log("OrderTotal handleParent(): priceCheck.price = " + priceCheck.price);
  }, [priceCheck, handleParent]);
//   }, [handleParent, priceCheck]);
  // }, [priceCheck, handleParent, licenses]);

  const dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const format_currency = (currency: number) => {
    return dollarUS.format(currency).replace(/^(\D+)/, "$1");
  };

  return (
    <Box sx={sx}>
      <Typography>
      Order total:&nbsp;
      {priceCheck.price !== priceCheck.fullPrice &&
        <span style={{textDecoration: 'line-through'}}>{format_currency(priceCheck.fullPrice) + " "}</span>
      }
      {format_currency(priceCheck.price)}
      {priceCheck.price === priceCheck.fullPrice && 
        (formType === 'personal-upgrade' || formType === 'business-upgrade') &&
        <span> (Price will update when you enter a license key above.)</span>
      }
      </Typography>
    </Box>
  );
}
