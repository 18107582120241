import React from "react";
import { Box, Typography, Stack } from "@mui/material";

export interface FormBodyProps {
  formType: string;
}

        // <Typography variant="h4">
class FormHeader extends React.Component<FormBodyProps> {
  headerText() {
    switch (this.props.formType)
    {
    case "personal":
      return "Personal License Purchase";
    case "personal-upgrade":
      return "Personal License Upgrade";
    case "business":
      return "Business License Purchase";
    case "business-upgrade":
      return "Business License Upgrade";
    }
  }

  render() {
    const logo = require('../img/logo.png');

    return (
      <Stack spacing={2}>
        <Box display="flex" justifyContent="center">
          <img alt='Modern CSV Logo' src={String(logo)} />
        </Box>
        <Box sx={{ px: 1, py: 1 }}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h5">
            {this.headerText()}
            </Typography>
          </Box>
          <Box sx={{ mt: 2}}>
            {(this.props.formType === "personal") &&
              <Typography>
              Upgrading from a version 1 license? Click <a href="./personal-upgrade">here</a>.<br/>
              <br/>
              </Typography>
            }
            {(this.props.formType === "personal-upgrade") &&
              <Typography>
              Want the extra features from a Premium Business license? Click <a href="./business-upgrade">here</a>.<br/>
              </Typography>
            }
            {(this.props.formType === "personal" || this.props.formType === "personal-upgrade") &&
              <Typography>
              Your name and email address will appear in the license key.
              </Typography>
            }
            {(this.props.formType === "business") &&
              <Typography>
              Upgrading from version 1? Click <a href="./business-upgrade">here</a>.<br/>
              <br/>
              </Typography>
            }
            {(this.props.formType === "business" || this.props.formType === "business-upgrade") &&
              <Typography>
              The email address and either the company name or your name (if you leave company blank) will appear in the license key.
              </Typography>
            }
          </Box>
        </Box>
      </Stack>
    );
  }
}

export default FormHeader;
